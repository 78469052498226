import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

import { withNamespaces } from 'react-i18next'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="/assets/img/photos/8.jpg">
      <Helmet
        title={t('courstennis.meta.title')}
        meta={[
          {
            name: 'description',
            content:t('courstennis.meta.description')
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">



          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('courstennis.title3')}</h2>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p style={{ textAlign: 'left'}}>
                {t('courstennis.title3_description1')}
              </p >
              <p style={{ textAlign: 'left'}}>
                {t('courstennis.title3_description2')}
              </p>

              <p style={{ textAlign: 'left'}}>
              {t('courstennis.title3_contact_name')}<br />
              {t('courstennis.title3_contact_tel')}<br />
              {t('courstennis.title3_contact_email')}<br />
              </p>

              <div style={{ textAlign: 'left'}}>
                {t('courstennis.title3_description3')}
                <ul style={{ listStyle:'disc' }}>
                  <li style={{paddingLeft: "2em"}}><a href={t('courstennis.title3_lien1')} >{t('courstennis.title3_lien1')}</a></li>
                  <li style={{paddingLeft: "2em"}}><a href={t('courstennis.title3_lien2')} >{t('courstennis.title3_lien2')}</a></li>
                </ul>
                
                
              </div>
            </div>
          </div>

        </div>
      </section>
    </AltertineLayout>
  )
}

export default withNamespaces('home')(IndexPage)
